<template>
  <list-container-widget :title="widgetName">
    <b-row class="horizontal-placeholder">
      <b-col cols="12">
        <img :src="peoplePlaceholder">
      </b-col>
      <b-col cols="12">
        <p class="text-primary">
          {{ $t('available.message', { itemName: $tc('people.title', 2) }) }}
        </p>
      </b-col>
    </b-row>
  </list-container-widget>
</template>

<script>
import { BCard, BCol, BRow } from 'bootstrap-vue';
import WidgetLayoutMixin from '@/@core/mixins/widgets/WidgetLayoutMixin';
import PeoplePlaceholder from '@/assets/images/placeholders/light/people.svg';
import ListContainerWidget from '@core/widgets/ListContainerWidget.vue';

export default {
  name: 'PeopleListEmptyWidget',
  components: {
    BCard,
    BCol,
    BRow,
    ListContainerWidget,
  },
  mixins: [WidgetLayoutMixin],
  computed: {
    peoplePlaceholder() {
      return PeoplePlaceholder;
    },
    hasPeopleType() {
      return this.widgetName.includes('Persones separades per tiups') || this.widgetName.includes('People by type') || this.widgetName.includes('Personas separadas por tipo');
    }
  },
};
</script>
